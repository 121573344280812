<template>
  <div>
    <div class="statistics-product">
      <div class="item">
        <div>头像</div>
        <div>昵称</div>
        <div>浏览量</div>
        <div>分享</div>
        <div>推荐</div>
        <div>订单</div>
        <div>分润</div>
      </div>
      <div class="item td" v-for="item in items" :key="item">
        <div>
          <img style="width: 25px;height: 25px;margin-top: 5px;" :src="item.user.avatar" alt="">
        </div>
        <div>{{item.user.nickName || item.user.name}}</div>
        <div>{{item.browseNumber}}</div>
        <div>{{item.shareNumber}}</div>
        <div>{{item.recommendNumber}}</div>
        <div>{{item.orderNumber}}</div>
        <div>{{item.redenvelopes}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, onMounted, reactive, toRefs } from 'vue'
export default {
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const state = reactive({
      storeId: Cookies.get('storeId'),
      applymentStateDesc: '',
      userId: Cookies.get('userId'),
      staffId: Cookies.get('staffId'),
      items: []
    })
    const init = () => {
      post('/allianceinvite.liststatis', {
        pageNum: 1,
        pageSize: 999,
        storeId: state.storeId
      }).then(res => {
        state.items = res.data.content
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>

<style scoped>
  .statistics-product{
    background-color: #fff;
    border: 1px solid #f3f3f3;
    box-sizing: border-box;
  }
  .statistics-product .item{
    height: 35px;
    line-height: 35px;
    display: flex;
    border-bottom: 1px solid #f3f3f3;
    overflow: hidden;
    word-break: break-word; /* 文本行的任意字内断开 */
    word-wrap: break-word; /* IE */
    white-space: -moz-pre-wrap; /* Mozilla */
    white-space: -hp-pre-wrap; /* HP printers */
    white-space: -o-pre-wrap; /* Opera 7 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: pre; /* CSS2 */
    white-space: pre-wrap; /* CSS 2.1 */
    white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
  }
  .statistics-product .item div{
    width: 14.27%;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    border-right: 1px solid #f3f3f3;
  }
  .statistics-product .item div:last-child{
    border-right: 0;
  }
  .statistics-product .td{
    font-size: 10px;
    font-weight: 400;
    color: #888;
  }
</style>
